<template>
  <div id="goods-details">
    <div id="breadcrumb">
      <div class="breadcrumb-con">
        <p>您的位置：</p>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item to="/Home">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            @click.native="breadcrumbClick(list.rank)"
            v-for="list in breadList"
            :key="list.categoryId"
            style="cursor: pointer"
            >{{ list.categoryName }}</el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-if="$route.query.id"
            :to="{
              path: `/Brand/RecommendedBrand?id=${$route.query.id}&brandId=${$route.query.brandId}`,
            }"
            >推荐品牌</el-breadcrumb-item
          >
          <el-breadcrumb-item to="/Brand/AllBrand" v-if="$route.query.brandName"
            >全部品牌</el-breadcrumb-item
          >
          <el-breadcrumb-item
            :to="{
              path: `/Brand/BrandGoods?brandId=${$route.query.brandId}&brandName=${$route.query.brandName}`,
            }"
            v-if="$route.query.brandName"
            >{{ $route.query.brandName }}</el-breadcrumb-item
          >
          <el-breadcrumb-item to="/AllGoods" v-if="$route.query.allGoods"
            >全部商品</el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><span @click="goBack" style="cursor: pointer"
              >关联店铺</span
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item>{{
            CommodityDetails.commodityName
          }}</el-breadcrumb-item>
        </el-breadcrumb>
        <svg-icon icon-class="ic-close" @click="goBack" />
      </div>
    </div>
    <div class="goods-details-con">
      <div class="top">
        <div class="commodity-img-box">
          <video
            :src="video"
            style="width: 460px; height: 460px"
            controls
            v-show="videoShow"
          ></video>
          <pic-zoom
            :url="info"
            :scale="2"
            class="commodity-img"
            v-show="!videoShow"
          ></pic-zoom>
          <div class="commodity-img-list">
            <img
              src="@/assets/images/Goods-left.png"
              class="arrow arrow-left"
              @click="leftclick"
            />
            <div>
              <ul ref="ulWidth">
                <li class="video" v-if="video">
                  <img
                    :src="filePath[0]"
                    alt=""
                    :class="videoShow ? 'onImages' : ''"
                    @click="videoBtn"
                  />
                  <svg-icon icon-class="play" @click="videoBtn" />
                </li>
                <li v-for="(pic, i) in filePath" :key="i">
                  <img
                    v-lazy="pic"
                    class="commodity-img-small"
                    :class="i === imgIndex && !videoShow ? 'onImages' : ''"
                    @click="selected(pic, i)"
                    :key="i"
                  />
                </li>
              </ul>
            </div>
            <img
              src="@/assets/images/Goods-right.png"
              class="arrow arrow-right"
              @click="rightclick"
            />
          </div>
        </div>
        <div class="right">
          <div class="title">
            <h2>
              商品名称：<span :title="CommodityDetails.commodityName">{{
                CommodityDetails.commodityName
              }}</span>
            </h2>
            <p>
              商品条形码：<span>{{ CommodityDetails.barcode }}</span>
            </p>
          </div>
          <div class="price-box">
            <p>
              会员价：
              <span
                >￥<i>{{
                  preferentialTime
                    ? PriceDetails.preferentialRetailPrice
                    : PriceDetails.retailPrice | Price
                }}</i></span
              >
              <!-- <span>￥<i>104.00</i></span> -->
              <span v-if="preferentialTime" class="line_through"
                >[<i>￥{{ PriceDetails.retailPrice | Price }}</i
                >]</span
              >
              <!-- <span class="line_through">[<i>￥450.00</i>]</span> -->
            </p>
            <p v-if="userInfo.userEntity.userType === 'PURCHASE'">
              店长价：
              <span
                >￥<i>{{
                  preferentialTime
                    ? PriceDetails.preferentialTradePrice
                    : PriceDetails.tradePrice | Price
                }}</i></span
              >
              <!-- <span>￥<i>90.00</i></span> -->
              <!--  -->
              <span class="line_through" v-if="preferentialTime"
                >[<i>￥{{ PriceDetails.tradePrice | Price }}</i
                >]</span
              >
              <!-- <span class="line_through">[<i>￥300.00</i>]</span> -->
            </p>
            <p
              v-if="
                userInfo.userEntity.userType === 'PURCHASE' &&
                (PriceDetails.conditionSetting === 2 ||
                  PriceDetails.preferentialConditionSetting === 2)
              "
              class="minimum"
            >
              （订单起订量：
              <span
                >≥{{
                  preferentialTime
                    ? PriceDetails.pTradeMinimumQuantity
                    : PriceDetails.tradeMinimumQuantity
                }}{{ CommodityDetails.prickle }}</span
              >）
              <!-- <span>10／千克</span> -->
            </p>
            <!-- -->
            <p
              class="minimum"
              v-if="
                userInfo.userEntity.userType === 'PURCHASE' &&
                (PriceDetails.conditionSetting === 1 ||
                  PriceDetails.preferentialConditionSetting === 1)
              "
            >
              （订单起订价格：
              <span
                >≥{{
                  preferentialTime
                    ? PriceDetails.ptradeStartingPrice
                    : PriceDetails.tradeStartingPrice | Price
                }}元</span
              >）
              <!-- <span>10／千克</span> -->
            </p>
          </div>

          <div class="content">
            <div>
              <p>
                生产厂商：<span :title="CommodityDetails.manufacturer">{{
                  CommodityDetails.manufacturer
                }}</span>
              </p>
              <p>
                归属品牌：<span :title="CommodityDetails.brandName">{{
                  CommodityDetails.brandName
                }}</span>
              </p>
              <p>
                商品重量：<span :title="CommodityDetails.weight"
                  >{{ CommodityDetails.weight }}千克</span
                >
              </p>
              <p>
                储藏方法：<span :title="CommodityDetails.conditions">{{
                  CommodityDetails.conditions
                }}</span>
              </p>
              <p>
                保质期限：<span :title="CommodityDetails.expirationDate"
                  >{{ CommodityDetails.expirationDate }}天</span
                >
              </p>
              <p>
                商品规格：<span :title="CommodityDetails.specsParameter">{{
                  CommodityDetails.specsParameter
                }}</span>
              </p>
              <p>
                配送方式：<span>
                  {{ CommodityDetails.freeShipping ? "包邮" : "自提" }}
                </span>
              </p>
            </div>
            <div>
              <p>
                厂商地址：<span :title="CommodityDetails.address">{{
                  CommodityDetails.address
                }}</span>
              </p>
              <p>
                商品产地：<span
                  :title="CommodityDetails.province + CommodityDetails.city"
                  >{{ CommodityDetails.province
                  }}{{ CommodityDetails.city }}</span
                >
              </p>
              <p>
                计量单位：<span :title="CommodityDetails.prickle">{{
                  CommodityDetails.prickle
                }}</span>
              </p>
              <p>运费设置：<span>包邮</span></p>
              <p>
                退货设置：<span
                  >{{
                    CommodityDetails.returnSetting ? "不" : ""
                  }}支持7天无理由退货</span
                >
              </p>
              <p>
                发货规则：<span>新疆/西藏等边远地区不发货，解释权归平台</span>
              </p>
            </div>
            <p class="td">
              商品特点：
              <span :title="CommodityDetails.characteristics">{{
                CommodityDetails.characteristics
                  ? CommodityDetails.characteristics
                  : "无"
              }}</span>
            </p>
          </div>
          <i class="dotted-line"></i>
          <div class="buy">
            <el-input-number
              v-if="userInfo.userEntity.userType === 'PURCHASE'"
              v-model="num2"
              @change="handleChange2"
              :min="
                PriceDetails.conditionSetting === 2
                  ? PriceDetails.tradeMinimumQuantity
                  : 1
              "
              :step="
                PriceDetails.multiple ? PriceDetails.tradeMinimumQuantity : 1
              "
            ></el-input-number>
            <el-input-number
              v-else
              v-model="num"
              @change="handleChange"
              :min="1"
            ></el-input-number>
            <div class="add-to-shopping-cart" @click="AddShoppingCart">
              <svg-icon icon-class="ic-shoppingCart-b" />
              加入购物车
            </div>
            <div class="view-trace-tracing" @click="goTrackBack">
              <svg-icon icon-class="eye" />
              认证查询
            </div>
            <!-- <p>
                库存：<i>{{ CommodityDetails.inventory }}</i
                >{{ CommodityDetails.prickle }}
              </p> -->

            <p>累计销量：<span>9999+</span></p>
          </div>
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
            class="el-dialog__wrapper2"
            :center="true"
          >
            <p>已有店长账号？请登录</p>
            <p>还没有店长账号？请注册</p>
            <span slot="footer" class="dialog-footer">
              <!-- <el-button @click="$router.push('/Login/ToRegister')"
                >注册店长</el-button
              > -->
              <el-button type="primary" @click="$router.push('/Login')"
                >登录</el-button
              >
            </span>
          </el-dialog>
          <el-dialog
            title="提示"
            :visible.sync="centerDialogVisible"
            width="30%"
            center
          >
            <span>需要登录</span>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="$router.push('/Login')"
                >前往登录页</el-button
              >
              <el-button @click="centerDialogVisible = false">取 消</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
      <div class="the-introduction">
        <div class="title">商品详情</div>
        <div
          class="introduce-img"
          v-html="CommodityDetails.commodityContent"
        ></div>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div class="tips" v-show="Tips">
        <div class="box">
          <div class="title">
            <span>添加成功</span>
            <button @click="Tips = false">×</button>
          </div>
          <div class="content">
            <p>
              <img src="@/assets/images/accept.png" alt="" />
              货品已添加至购物车！
            </p>
            <p>
              当前购物车共<span>{{ userSum }}</span
              >件商品
            </p>
            <p>
              <a href="#/ShoppingCart" target="_blank">去结算</a
              ><a @click="Tips = false">继续购物</a>
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getclassificationArray, formatDate } from "@/utils/validate";

import PicZoom from "vue-piczoom";
import Cookies from "js-cookie";
import { mapState } from "vuex";

export default {
  name: "goods-details",
  components: {
    PicZoom,
  },
  computed: {
    ...mapState("personalUser", ["userLogin", "userSum", "userInfo"]),
    ...mapState("user", ["brandId"]),
    breadList() {
      let breadList = [];
      if (this.$route.query.breadcrumb) {
        let category = JSON.parse(localStorage.getItem("category"));
        this.categoryNum = JSON.parse(this.$route.query.breadcrumb);
        let index = this.categoryNum;
        breadList.push(category[index[0]]);
        let arr = getclassificationArray(category[index[0]]);
        if (index[1] != undefined) {
          breadList.push(breadList[0].subItemList[index[1]]);
          arr = getclassificationArray(breadList[0].subItemList[index[1]]);
        }
        if (index[2] != undefined) {
          breadList.push(
            breadList[0].subItemList[index[1]].subItemList[index[2]]
          );
          arr = getclassificationArray(
            breadList[0].subItemList[index[1]].subItemList[index[2]]
          );
        }
        this.category = arr;
      }
      return breadList;
    },
  },
  data() {
    return {
      num: 1,
      num2: 1,
      info: "",
      filePath: [],
      li: 0,
      ulW: 0, //ul的定位位置
      CommodityDetails: "", //商品详情
      PriceDetails: "", //商品价格详情
      preferentialTime: false, //是否优惠
      centerDialogVisible: false,
      dialogVisible: false,
      imgIndex: 0,
      Tips: false,
      ulW: 0,
      videoShow: false,
      video: "",
    };
  },
  filters: {
    //处理函数
    Price(p) {
      if (p === undefined || p === null) {
        return "-";
      } else {
        if (String(Number(p)).indexOf(".")) {
          return p.toFixed(2);
        } else {
          return p;
        }
      }
    },
  },
  created() {
    this.getDetails();
    this.getPriceDetails();
  },
  mounted() {
    if (this._isMobile()) {
      // 移动
      window.location.href = `http://sgmall.origmall.cn/#/GoodsDetails?&enterpriseId=${this.$route.query.enterpriseId}&barcode=${this.$route.params.barcode}`;
    } else {
      // pc
    }
  },
  methods: {
    //获取商品详情
    async getDetails() {
      const data = await this.$API.store.getCommodityDetails({
        object: {
          barcode: this.$route.params.barcode,
          enterpriseId: this.$route.query.enterpriseId,
          commId: this.$route.query.commId,
        },
      });
      this.CommodityDetails = data.data.data;
      // this.filePath = data.data.data.filePath.split(",").map((pic, i) => {
      //   return i === 0 ? pic : "http://hwpicture.realbrand.net/" + pic;
      // });
      this.filePath = data.data.data.filePath
        .split(",")
        .filter((item) => !item.endsWith(".mp4"));
      this.info = this.filePath[0];
      this.video = data.data.data.filePath
        .split(",")
        .filter((item) => item.endsWith(".mp4"))[0];
      if (this.video) this.videoShow = true;
      this.$nextTick(function () {
        this.index = this.$refs.ulWidth.children.length - 5;
      });
    },
    //获取商品价格详情
    async getPriceDetails() {
      const data = await this.$API.store.getCommodityPriceDetails({
        object: {
          barcode: this.$route.params.barcode,
          enterpriseId: this.$route.query.enterpriseId,
          commId: this.$route.query.commId,
        },
      });
      this.PriceDetails = data.data.data;
      let now = new Date();
      let m = now.getTime();
      if (
        this.PriceDetails.preferentialStartTime < m &&
        m < this.PriceDetails.preferentialEndTime
      ) {
        this.preferentialTime = true;
      } else {
        this.preferentialTime = false;
      }
      if (this.preferentialTime) {
        if (this.PriceDetails.preferentialConditionSetting === 2) {
          this.num2 = this.PriceDetails.ptradeMinimumQuantity;
        } else if (this.PriceDetails.preferentialConditionSetting === 1) {
          this.num2 =
            Math.floor(
              this.PriceDetails.ptradeStartingPrice /
                this.PriceDetails.preferentialTradePrice
            ) + 1;
        }
      } else {
        if (this.PriceDetails.conditionSetting === 2) {
          this.num2 = this.PriceDetails.tradeMinimumQuantity;
        } else if (this.PriceDetails.conditionSetting === 1) {
          this.num2 =
            Math.floor(
              this.PriceDetails.tradeStartingPrice /
                this.PriceDetails.tradePrice
            ) + 1;
        }
      }
    },
    //加购物车
    async addShoppingCart() {
      if (this.userLogin) {
        const data = await this.$API.personalUser
          .InsertCusOrderCart({
            object: {
              barcode: this.$route.params.barcode, // 条形码
              commId: this.CommodityDetails.id, //商品id
              quantity: this.num2, //购买数量
              storeId: this.$route.params.id, //店铺id
              userId: sessionStorage.getItem("userId"), //用户id
            },
          })
          .then(() => {
            this.Tips = true;
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("加入购物车失败");
          });
        this.$store.dispatch("personalUser/getShoppingCartSum");
      } else {
        if (this.brandId === this.$route.query.enterpriseId) {
          this.$message.error("不能添加自己的商品");
        } else {
          const data = await this.$API.order
            .addShoppingCart({
              object: {
                barcode: this.$route.params.barcode, // 条形码
                commId: this.CommodityDetails.id, //商品id
                quantity: this.num, //购买数量
                storeId: this.$route.params.id, //店铺id
              },
            })
            .then(() => {
              this.$message({
                message: "加入购物车成功",
                type: "success",
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("加入购物车失败");
            });
          this.$store.dispatch("user/getCartSum");
        }
      }
    },
    //加减事件
    handleChange2() {
      let goods = this.PriceDetails;
      if (this.preferentialTime) {
        if (goods.preferentialConditionSetting === 2) {
          this.num2 === undefined && (this.num2 = goods.ptradeMinimumQuantity);
          if (
            goods.multiple === 1 &&
            !Number.isInteger(this.num2 / goods.ptradeMinimumQuantity)
          ) {
            this.$message.error("需整倍订货");
            this.num2 = goods.ptradeMinimumQuantity;
          }
        } else if (goods.preferentialConditionSetting === 1) {
          if (
            this.num2 <
            Math.floor(
              this.PriceDetails.ptradeStartingPrice /
                this.PriceDetails.preferentialTradePrice
            ) +
              1
          ) {
            this.$message.error("购买数量不能低于特惠价");
          } else {
            this.num2 =
              Math.floor(
                this.PriceDetails.ptradeStartingPrice /
                  this.PriceDetails.preferentialTradePrice
              ) + 1;
          }
        }
      } else if (!this.preferentialTime) {
        if (goods.conditionSetting === 2) {
          this.num2 === undefined && (this.num2 = goods.tradeMinimumQuantity);
          if (
            goods.multiple === 1 &&
            !Number.isInteger(this.num2 / goods.tradeMinimumQuantity)
          ) {
            this.$message.error("需整倍订货");
            this.num2 = goods.tradeMinimumQuantity;
          }
        } else if (goods.conditionSetting === 1) {
          if (
            this.num2 <
            Math.floor(
              this.PriceDetails.tradeStartingPrice /
                this.PriceDetails.tradePrice
            ) +
              1
          ) {
            this.$message.error("购买数量不能低于特惠价");
          } else {
            this.num2 =
              Math.floor(
                this.PriceDetails.tradeStartingPrice /
                  this.PriceDetails.tradePrice
              ) + 1;
          }
        }
      } else {
        this.num2 === undefined && (this.num2 = "1");
      }
    },
    handleChange() {
      if (this.num === undefined) this.num = "1";
    },
    //放大镜
    selected(src, i) {
      this.info = src;
      this.imgIndex = i;
      this.videoShow = false;
    },
    videoBtn() {
      this.videoShow = true;
    },
    rightclick() {
      if (this.index > 0) {
        this.index--;
        this.ulW += -76;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },
    leftclick() {
      if (this.index < this.$refs.ulWidth.children.length - 5) {
        this.index++;
        this.ulW += 76;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },

    goTrackBack() {
      this.$router.push({
        name: "track-back",
        query: {
          id: this.$route.params.barcode,
          enterpriseId: this.$route.query.enterpriseId
            ? this.$route.params.enterpriseId
            : this.CommodityDetails.enterpriseId,
        },
      });
    },

    AddShoppingCart() {
      let token = Cookies.get("KAI_HUA_ADMIN_TOKEN");
      let tz = this.userInfo.userEntity.userType;
      // if (tz != "PURCHASE") {
      //   this.dialogVisible = true;
      // } else
      //  {
      if (token || this.userLogin) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.addShoppingCart();
          // if (this.CommodityDetails.inventory) {
          //   this.addShoppingCart();
          // } else {
          //   this.$message({
          //     message: "商品库存不足",
          //     type: "warning",
          //   });
          // }
        }, 500);
      } else {
        this.centerDialogVisible = true;
      }
      // }
    },
    goBack() {
      this.$router.push({
        name: "store-list",
        query: {
          barcode: this.$route.params.barcode
            ? this.$route.params.barcode
            : this.CommodityDetails.barcode,
          enterpriseId: this.$route.query.enterpriseId
            ? this.$route.params.enterpriseId
            : this.CommodityDetails.enterpriseId,
          breadcrumb: this.$route.query.breadcrumb,
          id: this.$route.query.id,
          brandId: this.$route.query.brandId,
          brandName: this.$route.query.brandName,
          allGoods: this.$route.query.allGoods,
        },
      });
    },

    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
#goods-details {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  #breadcrumb {
    background: #f8f8f8;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    .breadcrumb-con {
      width: 1200px;
      height: 55px;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        color: #999;
      }
      ::v-deep.el-breadcrumb {
        span {
          color: #999;
        }
        .el-breadcrumb__inner {
          font-weight: normal;
        }
        .el-breadcrumb__inner:hover {
          color: var(--subjectColor);
          span {
            color: var(--subjectColor);
          }
        }
      }
      ::v-deep.el-breadcrumb .el-breadcrumb__item:last-child span {
        color: #333;
        cursor: default;
      }
      .svg-icon {
        margin-left: 4px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
      .svg-icon:hover {
        color: #000;
      }
    }
  }

  .goods-details-con {
    display: flex;
    flex-direction: column;
    .top {
      width: 1200px;
      margin: 24px 0 40px 0;
      display: flex;
      .commodity-img-box {
        display: flex;
        flex-direction: column;
        video {
          border: 1px solid #eee;
        }
        ::v-deep .magnifier-box {
          border: 1px solid #eee;
          img {
            // width: auto;
            // height: auto;
            width: 100%;
            height: 100%;
          }
        }
        .commodity-img {
          width: 460px;
          height: 460px;
          img {
            width: 460px;
            height: 460px;
          }
        }

        .commodity-img-list {
          margin-top: 14px;
          width: 460px;
          position: relative;
          div {
            width: 384px;
            overflow: hidden;
            position: relative;
            height: 60px;
            margin-left: 30px;
            ul {
              position: absolute;
              left: 0;
              transition: all 0.5s;
              .video {
                position: relative;
                img {
                  width: 60px;
                  height: 60px;
                  cursor: pointer;
                  border: 2px solid #fff;
                }
                .svg-icon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  font-size: 20px;
                  margin-top: -10px;
                  margin-left: -10px;
                  color: #999;
                  cursor: pointer;
                }
              }
              li {
                float: left;
                margin-left: 16px;
                .commodity-img-small {
                  width: 60px;
                  height: 60px;
                  border: 2px solid #fff;
                  cursor: pointer;
                }
                .onImages {
                  border: 2px solid var(--subjectColor);
                }
              }
            }
          }
          .arrow {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 15px;
            cursor: pointer;
          }
          .arrow-left {
            left: 0;
          }
          .arrow-right {
            right: 0;
          }
        }
      }
      .right {
        // width: 560px;
        height: 460px;
        margin-left: 24px;
        position: relative;
        .title {
          display: flex;
          line-height: 26px;
          color: #333333;
          justify-content: space-between;
          h2 {
            font-size: 20px;
            color: #888888;
            width: 510px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              color: #333333;
            }
          }
          p {
            font-size: 14px;
            color: #999999;
            flex-shrink: 0;
            span {
              color: var(--subjectColor);
            }
          }
        }
        .price-box {
          width: 716px;
          height: 68px;
          margin: 16px 0;
          display: flex;
          align-items: center;
          background: url("../../assets/images/price-bg.png");
          p {
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            height: 20px;
            span:first-child {
              color: #ec382f;
              i {
                font-size: 22px;
              }
            }
            .line_through {
              margin-left: 4px;
              i {
                text-decoration: line-through;
              }
            }
          }
          p:first-child {
            width: 342px;
            margin-left: 8px;
          }
          p:nth-child(2) {
            margin-right: 2px;
            flex-shrink: 0;
          }
          .minimum {
            line-height: 16px;
            height: 16px;
            flex-shrink: 0;

            span {
              color: var(--subjectColor);
            }
          }
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          .td {
            font-size: 14px;
            color: #888888;
            display: flex;
            line-height: 20px;
            span {
              width: 640px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          div {
            flex: 1;
            p {
              font-size: 14px;
              height: 20px;
              color: #888888;
              display: flex;
              line-height: 20px;
              margin-bottom: 10px;
              span {
                width: 272px;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .dotted-line {
          width: 100%;
          border-top: 1px dashed #ccc;
          display: block;
          margin-top: 16px;
        }
        .buy {
          width: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 0;
          .el-input-number {
            line-height: 36px;
            width: 160px;
            height: 36px;
            margin-right: 20px;
            ::v-deep.el-input-number__decrease {
              width: 36px;
              height: 34px;
              top: 2px;
              line-height: 34px;
              color: #333;
              .el-icon-minus:before {
                font-weight: 900;
              }
            }
            .el-input-number__decrease:hover {
              color: #333;
            }
            ::v-deep.el-input-number__increase {
              width: 36px;
              height: 34px;
              top: 2px;
              line-height: 34px;
              color: #333;
              .el-icon-plus:before {
                font-weight: 900;
              }
            }
            .el-input-number__increase:hover {
              color: #333;
            }
            ::v-deep.el-input__inner {
              border-radius: 0;
              height: 36px;
              border-color: #ddd;
            }
            ::v-deep.el-input__inner:focus {
              border-color: none;
            }
          }
          // p:last-child {
          //   color: #999999;
          //   i {
          //     color: var(--subjectColor);
          //     margin-right: 4px;
          //   }
          // }

          p:last-child {
            color: #999999;
            position: absolute;
            right: 0;
            span {
              color: var(--subjectColor);
            }
          }
        }
        ::v-deep .el-dialog__wrapper {
          .el-dialog__body {
            text-align: center;
            font-size: 16px;
          }
          .el-dialog__footer {
            .el-button--primary {
              background: var(--subjectColor);
              border: var(--subjectColor) 1px solid;
            }
            .el-button--default:hover {
              color: var(--subjectColor);
              background: rgba($color: #fff, $alpha: 0.2);
              border-color: rgba($color: var(--subjectColor), $alpha: 0.2);
            }
          }
        }
        ::v-deep .el-dialog__wrapper2 {
          .el-dialog__header {
            padding: 16px 20px;
          }
          .el-dialog__body {
            padding-top: 0;
            text-align: center;
            p:nth-child(2) {
              font-size: 14px;
              color: var(--subjectColor);
            }
            p {
              font-size: 20px;
              margin-bottom: 4px;
            }
          }
        }
        .add-to-shopping-cart {
          width: 148px;
          height: 42px;
          background: var(--subjectColor);
          border-radius: 4px;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 42px;
          margin-right: 15px;
          cursor: pointer;
          .svg-icon {
            font-size: 16px;
          }
        }
        .view-trace-tracing {
          width: 148px;
          height: 42px;
          border-radius: 4px;
          font-size: 16px;
          color: var(--subjectColor);
          text-align: center;
          border: 1px solid var(--subjectColor);
          line-height: 40px;
          cursor: pointer;
          .svg-icon {
            font-size: 16px;
          }
        }
      }
    }
    .the-introduction {
      width: 1200px;
      border: 1px solid #ddd;
      margin-bottom: 40px;
      .title {
        height: 40px;
        background: #f7f7f7;
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        color: #333333;
        padding-left: 20px;
        border-bottom: 1px solid #ddd;
      }
      ::v-deep .introduce-img {
        padding: 30px 180px 32px;
        img {
          width: 100%;
          vertical-align: bottom;
        }
      }
    }
  }
  .tips {
    background: rgba(0, 0, 0, 0.39);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 10000;
    .box {
      width: 560px;
      height: 191px;
      background: #fff;
      position: relative;
      left: 50%;
      top: 50%;
      margin-left: -280px;
      margin-top: -95px;

      .title {
        height: 46px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        span {
          font-weight: bold;
          font-size: 16px;
        }
        button {
          font-size: 30px;
          border: none;
          background: #fff;
          color: #666666;
          cursor: pointer;
        }
      }
      .content {
        padding: 33px 96px;
        p:nth-child(1) {
          position: relative;
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          img {
            position: absolute;
            left: -36px;
            top: -3px;
          }
        }
        p:nth-child(2) {
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          margin: 8px 0 12px 0;
          span {
            color: var(--subjectColor);
          }
        }
        p a {
          font-size: 12px;
          line-height: 16px;
          color: var(--subjectColor);
          margin-right: 20px;
          cursor: pointer;
        }
        p a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
<style>
.mouse-cover-canvas {
  box-sizing: border-box;
  background: #fff;
  top: 424px !important;
}
</style>